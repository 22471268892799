export const pricingTiers = [
    {
        name: "Basic",
        href: "/sign-up",
        priceMonthly: 400,
        description: "Perfect for small businesses looking to kickstart their digital presence.",
    },
    {
        name: "Business",
        href: "/sign-up",
        priceMonthly: 500,
        description: "Perfect for established small and medium businesses to grow their client base with custom solutions.",
    },
    {
        name: "Ultimate",
        href: "/sign-up",
        priceMonthly: 600,
        description:
            "The ultimate offering which includes everything you need (technology services, digital marketing, and social media management) to expand your business reach"
    },
];