/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from "@heroicons/react/outline";
import { useLocation } from "react-router-dom";
// import {Link} from 'react-router-dom'

const StandardFeatures = [
  "Website only",
  "Basic SEO",
  "Basic content and stock images",
  "5 page static website with contact form",
  "Mobile optimized responsive design - We will make sure your website works well on all devices",
];
const PremiumFeatures = [
  "Website + A custom platform",
  "Everything included in Basic plan+",
  "Advanced SEO",
  "Advanced contact us options",
  "Review collection software - An intelligent solution to direct your happy customers to review you on Business directories",
  "Unlimited changes/revisions - Make unlimited changes to website design or contents",
  "Custom platform development: To take reservations/appointments online, set-up an ecommerce store, etc.",
];
const BusinessFeatures = [
  "Website + A custom platform + Social media management + Digital Marketing",
  "Everything included in Basic and Business Boost plan+ ",
  "4 months of Social media management included - We will set-up and manage your social profiles for you",
  "4 months of Digital marketing included - We will do tailored digital marketing  to boost brand awareness and gain more cusotmers",
  "Dedicate account manager - A dedicated POC to answer all your queries",
  "Dedicate growth manager - Research and work with you to grow your business online",
  "Unlimited edits & Revision - Update your website design or contents as many times as you wish, without any extra charges ",
  "Advanced SEO - Our senior SEO manager will help us rank better, build backlinks and rank better on searched",
];

export default function Pricing({ loggedIn }) {
  // const ResetLocation = () => window.scrollTo(0, 0)
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const offerId = queryParams.get("offerId");

  const price = {
    basic: offerId ? 300 : 300,
    boost: offerId ? 400 : 400,
    ultimate: offerId ? 500 : 500,
  };

  return (
    <article className="bg-gray-900">
      <section id="pricing" className="pt-12 px-4 sm:px-6 lg:px-8 lg:pt-20 bg-gray-900">
        <section className="text-center">
          <h2 className="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">
            Pricing
          </h2>
          <h3 className="mt-2 text-3xl font-extrabold text-white sm:text-4xl lg:text-5xl">
            Our Plans
          </h3>
          <p className="mt-3 max-w-4xl mx-auto text-xl text-gray-300 sm:mt-5 sm:text-2xl">
            Choose a pricing plan according to your business goals
          </p>
        </section>
      </section>

      <section className="mt-16 gray-900 pb-12 lg:mt-20 lg:pb-20">
        <section className="relative z-0">
          <section className="absolute inset-0 h-5/6 bg-gray-900 lg:h-2/3" />
          <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <section className="relative lg:grid lg:grid-cols-7">
              <section className="mx-auto max-w-md lg:mx-0 lg:max-w-none lg:col-start-1 lg:col-end-3 lg:row-start-2 lg:row-end-3">
                <section className="h-full flex flex-col rounded-lg shadow-lg overflow-hidden lg:rounded-none lg:rounded-l-lg">
                  <section className="flex-1 flex flex-col">
                    <section className="bg-white px-6 py-10">
                      <section>
                        <h3
                          className="text-center text-2xl font-medium text-gray-900"
                          id="tier-hobby"
                        >
                          Basic
                        </h3>
                        <section className="mt-4 flex items-center justify-center">
                          <span className="px-3 flex items-center text-6xl tracking-tight text-gray-900">
                            {
                              // offerId
                              //   &&
                                (
                              <span className="text-2xl mr-2 font-medium line-through">
                                $400
                              </span>
                            )
                            }
                            <span className="mt-2 mr-2 text-4xl font-medium">
                              $
                            </span>
                            <span className="font-extrabold">
                              {price?.basic}
                            </span>
                          </span>
                          <span className="text-xl font-medium text-gray-500">
                            /month
                          </span>
                        </section>
                      </section>
                    </section>
                    <section className="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-50 sm:p-10 lg:p-6 xl:p-10">
                      <ul className="space-y-4">
                        {StandardFeatures.map((feature) => (
                          <li key={feature} className="flex items-start">
                            <section className="flex-shrink-0">
                              <CheckIcon
                                className="flex-shrink-0 h-6 w-6 text-green-500"
                                aria-hidden="true"
                              />
                            </section>
                            <p className="ml-3 text-base font-medium text-gray-500">
                              {feature}
                            </p>
                          </li>
                        ))}
                      </ul>
                      <section className="mt-8">
                        <section className="rounded-lg shadow-md">
                          <a
                            href="https://www.paypal.com/ncp/payment/38CM9ZEEFTVTL"
                            className="block w-full text-center rounded-lg border border-transparent bg-white px-6 py-3 text-base font-medium text-[color:var(--primary-font-color)] hover:bg-gray-50"
                            aria-describedby="tier-hobby"
                          >
                            Get Started
                          </a>
                        </section>
                      </section>
                    </section>
                  </section>
                </section>
              </section>
              <section className="mt-10 max-w-lg mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-start-3 lg:col-end-6 lg:row-start-1 lg:row-end-4">
                <section className="relative z-10 rounded-lg shadow-xl">
                  <section
                    className="pointer-events-none absolute inset-0 rounded-lg border-2 border-indigo-600"
                    aria-hidden="true"
                  />
                  <section className="absolute inset-x-0 top-0 transform translate-y-px">
                    <section className="flex justify-center transform -translate-y-1/2">
                      <span className="inline-flex rounded-full bg-indigo-600 px-4 py-1 text-sm font-semibold tracking-wider uppercase text-white">
                        Website + Social Media + Digital Marketing
                      </span>
                    </section>
                  </section>
                  <section className="bg-white rounded-t-lg px-6 pt-12 pb-10">
                    <section>
                      <h3
                        className="text-center text-3xl font-semibold text-gray-900 sm:-mx-6"
                        id="tier-growth"
                      >
                        Business Boost Ultimate
                      </h3>
                      <section className="mt-4 flex items-center justify-center">
                        <span className="px-3 flex items-center text-6xl tracking-tight text-gray-900 sm:text-6xl">
                          {
                            // offerId
                            //   &&
                              (
                            <span className="text-2xl mr-2 font-medium line-through">
                              $600
                            </span>
                          )
                          }
                          <span className="mt-2 mr-2 text-4xl font-medium">
                            $
                          </span>
                          <span className="font-extrabold">
                            {price?.ultimate}
                          </span>
                        </span>
                        <span className="text-2xl font-medium text-gray-500">
                          /month
                        </span>
                      </section>
                    </section>
                  </section>
                  <section className="border-t-2 border-gray-100 rounded-b-lg pt-10 pb-8 px-6 bg-gray-50 sm:px-10 sm:py-10">
                    <ul className="space-y-4">
                      {BusinessFeatures.map((feature) => (
                        <li key={feature} className="flex items-start">
                          <section className="flex-shrink-0">
                            <CheckIcon
                              className="flex-shrink-0 h-6 w-6 text-green-500"
                              aria-hidden="true"
                            />
                          </section>
                          <p className="ml-3 text-base font-medium text-gray-500">
                            {feature}
                          </p>
                        </li>
                      ))}
                    </ul>
                    <section className="mt-10">
                      <section className="rounded-lg shadow-md">
                        <a
                          // onClick={ResetLocation}
                          href="https://www.paypal.com/ncp/payment/UB9GDGE4KERGA"
                          className="block w-full text-center rounded-lg border border-transparent bg-indigo-600 px-6 py-4 text-xl leading-6 font-medium text-white hover:bg-indigo-700"
                          aria-describedby="tier-growth"
                        >
                          Get Started
                        </a>
                      </section>
                    </section>
                  </section>
                </section>
              </section>
              <section className="mt-10 mx-auto max-w-md lg:m-0 lg:max-w-none lg:col-start-6 lg:col-end-8 lg:row-start-2 lg:row-end-3">
                <section className="h-full flex flex-col rounded-lg shadow-lg overflow-hidden lg:rounded-none lg:rounded-r-lg">
                  <section className="flex-1 flex flex-col">
                    <section className="bg-white px-6 py-10">
                      <section>
                        <h3
                          className="text-center text-2xl font-medium text-gray-900"
                          id="tier-scale"
                        >
                          Business Boost
                        </h3>
                        <section className="mt-4 flex items-center justify-center">
                          <span className="px-3 flex items-center text-6xl tracking-tight text-gray-900">
                            {
                              // offerId &&
                                (
                              <span className="text-2xl mr-2 font-medium line-through">
                                $500
                              </span>
                            )}
                            <span className="mt-2 mr-2 text-4xl font-medium">
                              $
                            </span>
                            <span className="font-extrabold">
                              {price?.boost}
                            </span>
                          </span>
                          <span className="text-xl font-medium text-gray-500">
                            /month
                          </span>
                        </section>
                      </section>
                    </section>
                    <section className="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-50 sm:p-10 lg:p-6 xl:p-10">
                      <ul className="space-y-4">
                        {PremiumFeatures.map((feature) => (
                          <li key={feature} className="flex items-start">
                            <section className="flex-shrink-0">
                              <CheckIcon
                                className="flex-shrink-0 h-6 w-6 text-green-500"
                                aria-hidden="true"
                              />
                            </section>
                            <p className="ml-3 text-base font-medium text-gray-500">
                              {feature}
                            </p>
                          </li>
                        ))}
                      </ul>
                      <section className="mt-8">
                        <section className="rounded-lg shadow-md">
                          <a
                            // onClick={ResetLocation}
                            href="https://www.paypal.com/ncp/payment/RG559SC9GH9FS"
                            className="block w-full text-center rounded-lg border border-transparent bg-white px-6 py-3 text-base font-medium text-[color:var(--primary-font-color)] hover:bg-gray-50"
                            aria-describedby="tier-scale"
                          >
                            Get Started
                          </a>
                        </section>
                      </section>
                    </section>
                  </section>
                </section>
              </section>
            </section>
          </section>
        </section>
      </section>
    </article>
  );
}
