const companyStats = [
    {
        id: 1,
        stat: '20+',
        title: 'Projects',
        desc: 'and 100% satisfied business owners',
    },
    {
        id: 2,
        stat: '100k+',
        title: 'Followers Gained',
        desc: 'and they never stop coming',
    },
    {
        id: 3,
        stat: '+150%',
        title: 'Increased Engagement',
        desc: 'resulting in increased store visits',
    },
    {
        id: 4,
        stat: '+33%',
        title: 'Increased Leads',
        desc: 'and the business keeps getting better',
    },
]

export default companyStats;