const faq = [
    {
        question: "What if I already have a website?",
        answer: "No problem! We offer digital marketing and online booking/sales application solutions to enhance your existing site."
    },
    {
        question: "Can I customize my plan?",
        answer: "Absolutely! Our plans are flexible to meet your specific business needs."
    },
    {
        question: "What is the money-back guarantee?",
        answer: "We offer a 14-day money-back guarantee if you're not satisfied with our services."
    },
    {
        question: "How long does it take to set up my website?",
        answer: "Our team will set up your website within 7-10 business days."
    },
    {
        question: "Can I cancel my plan at any time?",
        answer: "Yes, you have the flexibility to cancel at any time. However, we're confident that you'll see the value in our services and the positive impact they'll have on your business."
    },
    {
        question: "What if I'm not happy with the results?",
        answer: "Your satisfaction is our priority. That's why we offer a 14-day money-back guarantee with our Ultimate Business Boost plan. If you're not completely satisfied, simply let us know, and we'll make it right."
    }
];

export default faq;