import Hero from "./Hero";
// import Partners from "./Partners";
import Feature from "./Feature";
// import Cta from "../../components/cta/Cta";
import Stats from "./Stats";
import AboutUs from "./AboutUs";
import Pricing from "./Pricing";
// import Team from "../../components/team/Team";
import Comparison from "./Comparison";
// import Newsletter from "../../components/Newsletter";
import TestimonialCarousel from "./TestimonialCarousel";
import FaqPage from "../faq/Faq";
import TextOnlyStats from "./TextOnlyStats";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import { useEffect } from "react";
import ContactUs from "./ContactUs";

function Landing({ loggedIn }) {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
    if (window.clarity && typeof window.clarity === "function") {
      window.clarity("trackPageview");
    } else {
      console.warn("Clarity script is not loaded yet.");
    }
  }, [location]);

  return (
    <article className="bg-gray-900">
      <Hero />
      <AboutUs />
      <Stats />

      <Feature />
      {/* <Cta g loggedIn={loggedIn} /> */}
      <Pricing loggedIn={loggedIn} />
      {/* <Partners />   */}

      <TestimonialCarousel />
      <FaqPage />

      {/* <Team /> */}
      <TextOnlyStats />
      <ContactUs />
      <Comparison loggedIn={loggedIn} />
      {/* <Newsletter /> */}
    </article>
  );
}

export default Landing;
