import React from 'react'
import Testimonial from './Testimonials'
import userAvatar from '../../assets/images/user avatar.jpeg'
import TominGeorge from '../../assets/images/TominGeorge.jpg'
import GireeshKumar from '../../assets/images/GireeshKumar.jpeg'
import JoelThomas from '../../assets/images/JoelThomas.jpg'
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.css'

const testimonials = [
    {
        img: TominGeorge,
        testimonial: "We had a wonderful experience working with Digievo Labs. Shaheer and team built the app and website for our educational social network platform, right from scratch. Their insights proved to be valuable not just in the tech development, but also in fine tuning our product offerings and brand positioning. Would highly recommend the team for build quality, timely delivery and clear communication, all at affordable service costs.",
        user: {
            name: "Ar. Tomin George",
            role: "Founder at Delopus Pvt Ltd"
        }
    },
    {
        img: GireeshKumar,
        testimonial: "Digievo Labs did an incredible job creating our online store. They brought our vision to life with their technology expertise and dedication. They exceeded our expectations and delivered a seamless, user-friendly platform for our FMEG business.",
        user: {
            name: "Gireesh Kumar Gopal",
            role: "CEO, Haward Systems LLP"
        }
    },
    {
        img: JoelThomas,
        testimonial: "Working with Digievo Labs has been amazing. The team is not just technically skilled but also very passionate and committed to delivering exceptional digital solutions. I truly appreciate their partnership and would love to work with them again.",
        user: {
            name: "Joel Thomas",
            role: "Ex Security Engineer at Amazon"
        }
    },
    {
        img: userAvatar,
        testimonial: "The team at Digievo Labs built an impressive online portfolio website for us that has significantly increased our online presence. Thanks to their work, we now receive leads directly through our website. I'm very pleased with their work and highly recommend them.",
        user: {
            name: "Ansar Jaffer",
            role: "CEO, Jas Advertising, UAE"
        }
    }
]

const TestimonialCarousel = () => {
    var settings = {
        dots: true,
        infinite: true,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 4000,
        fade: true
      };

    return(
        <div className='mb-10'>
            <Slider {...settings}>
                {
                    testimonials.map(testimonial => {
                        return (
                            <Testimonial
                                img={testimonial.img}
                                testimonial={testimonial.testimonial}
                                user={testimonial.user}
                            /> 
                        )
                    })
                }
            </Slider>
        </div>
    )
}

export default TestimonialCarousel;