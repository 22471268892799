// import { useState } from "react";
import EdgyIcon from "../../assets/images/edgy-icon.png";
import { Link } from "react-router-dom";

const links = [
  { name: "About", href: "#about-us", id: "about-us" },
  { name: "Pricing", href: "#pricing", id: "pricing" },
  { name: "FAQ", href: "#faq", id: "faq" },
  { name: "Contact", href: "#contact", id: "contact" },
];

export default function Navigation({
  loggedIn,
  setLoggedIn,
  setTriggeredLogout,
}) {
  // const navigate = useNavigate()
  const ResetLocation = () => window.scrollTo(0, 0);
  // const logOutUser = () => {
  //   setTriggeredLogout(true);
  //   setLoggedIn(false);
  //   navigate('/')
  // }

  // const ToggleSwitch = () => {
  //   const [isOn, setIsOn] = useState(false);
  //
  //   const toggleSwitch = () => {
  //     setIsOn(!isOn);
  //   };
  //
  //   return (
  //     <div
  //       className={`relative inline-flex items-center h-6 rounded-full w-11 cursor-pointer transition-colors duration-200 ${"bg-toggleBtnBlue"}`}
  //       onClick={toggleSwitch}
  //     >
  //       <span
  //         className={`inline-block w-4 h-4 transform bg-white rounded-full transition-transform duration-200 ${
  //           isOn ? "translate-x-5" : "translate-x-1"
  //         }`}
  //       />
  //     </div>
  //   );
  // };

  const handleScroll = (id) => {
    document.getElementById(id)?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <header className="bg-gray-900">
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
        <section className="w-full py-6 flex items-center justify-between border-b border-indigo-500 lg:border-none">
          <section className="flex items-center">
            <Link to="/" onClick={ResetLocation}>
              <span className="sr-only">Digievo Labs</span>
              <img className="h-10 w-auto" src={EdgyIcon} alt="Digievo" />
            </Link>
            <section className="ml-10 space-x-8">
              {links.map((link) => (
                <Link
                  onClick={() => handleScroll(link?.id)}
                  key={link.name}
                  to={link.href}
                  className="text-base font-medium text-white hover:text-indigo-50"
                >
                  {link.name}
                </Link>
              ))}
            </section>
          </section>
          {/* {loggedIn ? <section className="ml-10 space-x-4">
            <button onClick={() => { logOutUser() }} className="inline-block bg-indigo-500 py-2 px-4 border border-transparent rounded-md text-base font-medium text-white hover:bg-opacity-75">
              Sign out
            </button>
            <Link onClick={ResetLocation} to="/profile" className="inline-block bg-white py-2 px-4 border border-transparent rounded-md text-base font-medium text-indigo-600 hover:bg-indigo-50">
              Profile
            </Link>
          </section> : */}
          {/* <section className="ml-10 space-x-4">
            <span className="text-white">Already have a website? </span>{" "}
            {<ToggleSwitch />}
          </section> */}
          {/* } */}
        </section>
        {/* <section className="py-4 flex flex-wrap justify-center space-x-6 lg:hidden">
          {links.map((link) => (
            <div
              onClick={() => handleScroll(link?.id)}
              key={link.name}
              to={link.href}
              className="text-base font-medium text-white hover:text-indigo-50"
            >
              {link.name}
            </div>
          ))}
        </section> */}
      </nav>
    </header>
  );
}
