export const pricingSections = [
    {
        name: "Web Development",
        features: [
            {
                name: "4-Page website design.",
                tiers: {Basic: true, Ultimate: true, Business: true},
            },
            {
                name: "Mobile Optimization.",
                tiers: {Basic: true, Ultimate: true, Business: true},
            },
            {
                name: "Basic SEO",
                tiers: {Basic: true, Ultimate: true, Business: true},
            },
            {
                name: "Google Analytics Integration",
                tiers: {Basic: true, Ultimate: true, Business: true},
            },
            {
                name: "Free domain and hosting for 1 year",
                tiers: {Basic: true, Ultimate: true, Business: true},
            },
            {
                name: "Free security updates",
                tiers: {Basic: true, Ultimate: true, Business: true},
            },
            {
                name: "Contact form",
                tiers: {Ultimate: true, Business: true},
            },
            {
                name: "Custom Contact Widget",
                tiers: {Ultimate: true, Business: true},
            },
            {
                name: "Map & Navigate section",
                tiers: {Ultimate: true, Business: true},
            },
            {
                name: "Advanced SEO",
                tiers: {Ultimate: true},
            },
            {
                name: "Unlimited Custom Pages",
                tiers: {Ultimate: true},
            },
            {
                name: "Unlimited edits and revisions",
                tiers: {Ultimate: true},
            },
        ],
    },
    {
        name: "Business Growth",
        features: [
            {
                name: "User Review Collection",
                tiers: {Ultimate: true, Business: true},
            },
            {
                name: "Google Business Listing & Optimization",
                tiers: {Ultimate: true, Business: true},
            },
            {
                name: "Custom Booking Software",
                tiers: {Ultimate: true, Business: true}
            },
            {
                name: "Dedicated Account Manager & Priority Support",
                tiers: {Ultimate: true}
            }
        ],
    },
    {
        name: "Digital Marketing & Social Media",
        features: [
            {
                name: "3 Month Digital Marketing Campaigns",
                tiers: {Ultimate: true},
            },
            {
                name: "3 Month Social Media Management",
                tiers: {Ultimate: true}
            },
            {
                name: "Priority Growth Campaigns",
                tiers: {Ultimate: true},
            },
            {
                name: "Advanced Content Strategy",
                tiers: {Ultimate: true},
            },
        ],
    },
];
