export default function ContactUs() {
  async function handleSubmit(event) {
    event.preventDefault();
    const form = document.getElementById("contact-form");
    const name = form?.name.value;
    const email = form?.email.value;
    const phone = form?.phone.value;
    const subject = form?.subject.value;
    const message = form?.message.value;
    const data = {
      name,
      email,
      ...(phone && { phone }),
      subject,
      message,
    };
    const url = `https://digievo-functions.azurewebsites.net/api/smb-contact?code=bKO-RiUO_x3w7_7oJtYZBFfVtPPZe4Phs9XLjnZHMQmQAzFu7zUjOA%3D%3D`;
    try {
      await fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(data),
      });
      alert("Submission successful! Our team will get back to you soon.");
    } catch (error) {
      console.error(error.message);
      alert("Submission failed, please try again!");
    }
  }

  return (
    <article
      id="contact"
      className="flex flex-col justify-center items-center py-10 mx-2"
    >
      <h1 className="text-5xl font-bold mb-5 text-white">Contact Us</h1>
      <p className="text mb-5 text-white">To work with us, send your query and the details about your business to us. Our team will get back to you to set everything up.</p>
      <div className="max-w-3xl w-full text-center">
        <form id="contact-form" className="space-y-4" onSubmit={handleSubmit}>
          <div className="flex flex-col">
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Name"
              className="p-3 border border-gray-300 rounded-lg"
              required
            />
          </div>
          <div className="flex flex-col sm:flex-row gap-4">
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email Address"
              className="p-3 border border-gray-300 rounded-lg w-full"
              required
            />
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder="Optional"
              className="p-3 border border-gray-300 rounded-lg w-full"
            />
          </div>
          <div className="flex flex-col">
            <input
              type="text"
              id="subject"
              name="subject"
              placeholder="Subject"
              className="p-3 border border-gray-300 rounded-lg"
              required
            />
          </div>
          <div className="flex flex-col">
            <textarea
              id="message"
              name="message"
              rows="5"
              placeholder="Message"
              className="p-3 border border-gray-300 rounded-lg"
              required
            ></textarea>
          </div>
          <button
            className="inline-flex items-center justify-center px-20 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
    </article>
  );
}
