import { NavLink } from "react-router-dom";
import Linkedin from "../../assets/socials/linkedin-pink.svg";
import Github from "../../assets/socials/github-pink.svg";
import Portfolio from "../../assets/socials/portfolio.png";

const navigation = {
  contact: [
    { name: "+91 6282 768 505", href: "tel:+916282768505", exact: true },
    {
      name: "info@digievolabs.com",
      href: "mailto:info@digievolabs.com",
      exact: true,
    },
    {
      name: "Send us a message",
      href: "#contact",
      exact: false,
      id: "contact",
    },
  ],
  company: [
    { name: "About Us", href: "#about-us", id: "about-us" },
    { name: "Pricing", href: "#pricing", id: "pricing" },
    { name: "FAQ", href: "#faq", id: "faq" },
  ],
  legal: [
    {
      name: "Terms",
      href: "https://digievolabs.com/terms-and-conditions",
      exact: true,
    },
    {
      name: "Privacy",
      href: "https://digievolabs.com/privacy-policy",
      exact: true,
    },
    {
      name: "Refunds",
      href: "https://digievolabs.com/terms-and-conditions",
      exact: true,
    },
  ],
  social: [
    {
      name: "Facebook",
      href: "https://www.facebook.com/digievolabs/",
      icon: Github,
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com/digievolabs",
      icon: Portfolio,
    },
    {
      name: "Linkedin",
      href: "https://www.linkedin.com/company/digievolabs",
      icon: Linkedin,
    },
  ],
};

export default function Footer() {
  //   const ResetLocation = () => window.scrollTo(0, 0);
  const handleScroll = (id) => {
    document.getElementById(id)?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <footer className="bg-gray-900" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <section className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <section className="pb-8 xl:grid xl:grid-cols-5 xl:gap-8">
          <section className="grid grid-cols-2 gap-8 xl:col-span-4">
            <section className="md:grid md:grid-cols-2 md:gap-8">
              <section>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Contact Us
                </h3>
                <ul className="mt-4 space-y-4">
                  {navigation.contact.map((item) => (
                    <li key={item.name}>
                      {item?.exact ? (
                        <a
                          href={item.href}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-base text-gray-300 hover:text-white"
                        >
                          {item.name}
                        </a>
                      ) : (
                        <NavLink
                          onClick={() => handleScroll(item?.id)}
                          to={item.href}
                          className="text-base text-gray-300 hover:text-white"
                        >
                          {item.name}
                        </NavLink>
                      )}
                    </li>
                  ))}
                </ul>
              </section>
              <section className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Learn more
                </h3>
                <ul className="mt-4 space-y-4">
                  {navigation.company.map((item) => (
                    <li key={item.name}>
                      <NavLink
                        onClick={() => handleScroll(item?.id)}
                        to={item.href}
                        className="text-base text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </section>
            </section>
            <section className="md:grid md:grid-cols-2 md:gap-8">
              <section>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Legal
                </h3>
                <ul className="mt-4 space-y-4">
                  {navigation.legal.map((item) => (
                    <li key={item.name}>
                      {item?.exact ? (
                        <a
                          href={item.href}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-base text-gray-300 hover:text-white"
                        >
                          {item.name}
                        </a>
                      ) : (
                        <NavLink
                          //   onClick={ResetLocation}
                          to={item.href}
                          className="text-base text-gray-300 hover:text-white"
                        >
                          {item.name}
                        </NavLink>
                      )}
                    </li>
                  ))}
                </ul>
              </section>
            </section>
          </section>
        </section>

        <section className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
          <section className="flex space-x-6 md:order-2">
            {navigation.social.map((item) => (
              <a
                key={item.name}
                href={item.href}
                target="_blank"
                rel="noreferrer"
                className="text-gray-400 hover:text-gray-300"
              >
                <span className="sr-only">{item.name}</span>
                <img
                  className="h-6 w-6"
                  aria-hidden="true"
                  src={item.icon}
                  alt={item.icon}
                />
              </a>
            ))}
          </section>
          <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
            &copy; <span>{new Date().getFullYear()}</span> Digievo Labs Private
            Limited. All rights reserved.
          </p>
        </section>
      </section>
    </footer>
  );
}
