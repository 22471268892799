import PhoneIcon from '../assets/images/customer-support.png'
import SecurityIcon from '../assets/images/security.png'
import FollowerIcon from '../assets/images/followers.png'
import ContentIcon from '../assets/images/content-management.png'
import TargetIcon from '../assets/images/target-audience.png'
import PlanIcon from '../assets/images/payment-plan.png'

const features = [
    {
        name: 'Professional Website Design',
        description:
            'Get a custom-designed website that showcases your brand and attracts customers.',
        icon: PlanIcon,
    },
    {
        name: 'Logo Design and Branding',
        description:
            'Receive a custom-designed logo that represents your business identity.',
        icon: TargetIcon,
    },
    {
        name: 'Digital Marketing',
        description:
            '"Get a comprehensive digital marketing solution that drives traffic and sales to your business',
        icon: ContentIcon,
    },
    {
        name: 'Google My Business Listing',
        description:
            'Improve your local search visibility with our Google My Business listing and optimization services.',
        icon: FollowerIcon,
    },
    {
        name: 'SEO Optimisation',
        description:
            'Boost your website\'s search engine ranking with our SEO optimization services.',
        icon: SecurityIcon,
    },

    {
        name: 'Custom Addons',
        description:
            'We will help you with any custom software that you might require to help your business thrive. This can include appointment booking/reservation website, e-commerce site, etc.',
        icon: PhoneIcon,
    },
]

export default features;