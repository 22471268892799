import EdgyIcon from "../../assets/images/edgy-icon.png";

export default function TextOnlyStats() {
    return (
        <article className="relative bg-gray-900">
            <section
                className="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-flow-col-dense xl:gap-x-8">
                <section
                    className="relative sm:pt-12 sm:pb-12 xl:col-start-1 xl:pb-24 flex flex-col md:flex-row justify-center items-center">
                    <section className="md:mr-10 sm:my-0 my-12">
                        <img className="w-54" src={EdgyIcon} alt="Edgy"/>
                    </section>
                    <section>
                        <h2 className="text-sm font-semibold text-[color:var(--primary-font-color)] tracking-wide uppercase">
                            Our Risk-free Guarantee
                        </h2>
                        <p className="mt-3 text-3xl font-extrabold text-white">
                            Still not Convinced? Try our Services Risk-Free
                        </p>
                        <p className="mt-5 text-lg text-gray-300">
                            <span className="font-bold"> 14-Day Money Back Guarantee:</span> We're so confident you'll love our services, we offer a
                            risk-free trial. If you're not satisfied within 14 days, simply get a full refund.
                            <br/>
                            <span className="font-bold"> Cancel Anytime:</span> We believe in building long-term partnerships, but you're free to cancel at
                            any time with no strings attached.

                        </p>
                    </section>
                </section>
            </section>
        </article>
    )
}
